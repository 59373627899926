export const GetOrdinalSuffix = (num: number): string => {
    if (num <= 0) return `${num}`;
  
    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;
  
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return `${num}th`;
    }
  
    switch (lastDigit) {
      case 1:
        return `${num}st`;
      case 2:
        return `${num}nd`;
      case 3:
        return `${num}rd`;
      default:
        return `${num}th`;
    }
  };

 export  const CalculateEventOccurrences = (
    startDate: number, // Day of the month the event starts
    endDate: number,   // Day of the month the event ends
    recurrenceInterval: number, // Interval in months
    recurrenceEndDate: Date     // Date until the recurrence should continue
  ): number => {
    // Get the current date and set the start date
    const currentDate = new Date();
    currentDate.setDate(startDate);
  
    // Ensure the recurrence end date is valid
    const endRecurrenceDate = new Date(recurrenceEndDate);
    if (isNaN(endRecurrenceDate.getTime())) {
      throw new Error("Invalid recurrence end date");
    }
  
    // Adjust the start date if it exceeds the last day of the current month
    const getLastDateOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const lastDayOfMonth = getLastDateOfMonth(currentDate);
    const adjustedStartDate = Math.min(startDate, lastDayOfMonth);
    currentDate.setDate(adjustedStartDate);
  
    // Initialize the count of occurrences
    let occurrences = 0;
  
    // Loop through the months, incrementing by the recurrence interval
    while (currentDate <= endRecurrenceDate) {
      occurrences++;
  
      // Move the current date forward by the recurrence interval
      currentDate.setMonth(currentDate.getMonth() + recurrenceInterval);
  
      // Adjust the start and end dates if they exceed the last day of the new month
      const lastDayOfNewMonth = getLastDateOfMonth(currentDate);
      currentDate.setDate(Math.min(startDate, lastDayOfNewMonth));
    }
  
    return occurrences;
  };
  