import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Install this library using `npm install jwt-decode`
import { useAuthStore } from "../../store/useAuthStore";
import { logoImage } from "../project/TableIdConstants";
import { useApproveAmp, useRetriggerAmpMail } from "../../queries/admin";

interface DecodedToken {
  id: string;
  taskId: string;
  emailId: string;
  userId: string;
  projectId: string;
  iat: number; // Issued at
  exp: number; // Expiration time
}

const TaskApprovalEmail = () => {
  const [params, setParams] = useState({});
  const [userDetail, setUserDetails] = useState<any>(null);
  const [approvalState, setApprovalState] = useState("PENDING");
  const [comment, setComment] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isRegenerated, setIsRegenerated] = useState(false);
  const { setAuth } = useAuthStore();
  const [tokenData, setTokenData] = useState<string>("");

  const { mutate: approveAmp, isLoading } = useApproveAmp();
  const { mutate: retriggerMail, isLoading: isRetriggering } =
    useRetriggerAmpMail();

  const decodeToken = (token: string) => {
    try {
      const decoded: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decoded.exp < currentTime; // Check if the token is expired
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // Treat as expired if decoding fails
    }
  };

  const handleApprovalToggle = async (state: string) => {
    try {
      setShowCommentBox(false)
      approveAmp(
        {
          state,
          comment,
          token: tokenData
        },
        {
          onSuccess: () => {
            setApprovalState(state);
            setComment("");
            setTimeout(() => {
            window.close();}, 5000);
          },
          onError: (error) => {
            console.error("Error updating approval state:", error);
          },
        }
      );
    } catch (error) {
      console.error("Error updating approval state:", error);
    }
  };

  const handleRegenerateMail = (token: string) => {
    retriggerMail(token, {
      onSuccess: () => {
        setIsRegenerated(true);
      },
      onError: (error) => {
        console.error("Error regenerating mail:", error);
      },
    });
  };

  useEffect(() => {
    const extractParams = () => {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const paramsObject = Object.fromEntries(searchParams.entries());

      setParams(paramsObject);
      if (paramsObject && paramsObject["userName"]) {
        setUserDetails({
          userName: paramsObject["userName"] ?? "",
          taskName: paramsObject.taskName || "",
          projectName: paramsObject.projectName || "",
          taskDescription: paramsObject.taskDescription || "",
          viewDetailsUrl: paramsObject.taskLink || "",
          endDate: paramsObject.endDate || "",
          stateChangeUserName: paramsObject.stateChangeUserName || "",
          status: paramsObject.status || "Completed",
          id: paramsObject.id || "",
        });

        const token = paramsObject["token"];
        setTokenData(token);
        if (token) {
          const expired = decodeToken(token);
          setIsTokenExpired(expired);
          if (!expired) {
            setAuth({
              accessToken: token,
            });
          }
        }
      }

      const newUrl = `${window.location.origin}${window.location.pathname}`;
      window.history.replaceState({}, "", newUrl);
    };

    extractParams();
  }, [setAuth]);

  if (!userDetail)
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );

  return (
    <div className="font-sans leading-normal bg-[#f2f5f8] m-0 p-0 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-[600px] mx-auto my-5 bg-white rounded-lg overflow-hidden shadow-md">
        <div className="bg-gradient-to-b from-[#0c224a] to-[#2158be] p-10 text-center">
          <img src={logoImage} alt="Hissa" className="w-24 h-10 mx-auto mb-4" />
          <h1 className="text-white text-2xl font-bold">
            Task Approval Needed
          </h1>
        </div>

        <div className="p-8">
          <p>
            Hi <strong>{userDetail.userName}</strong>,
          </p>
          <p>
            The status for the task titled '{userDetail.taskName}' under the
            project '{userDetail.projectName}' has been updated to '
            {userDetail.status}' by {userDetail.stateChangeUserName}.
          </p>

          <div className="bg-[#f8f8f8] rounded-md p-5 mb-5">
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Task Description:
            </h2>
            <p className="text-gray-600 pl-5">{userDetail.taskDescription}</p>
          </div>

          {isTokenExpired ? (
            <>
            {!isRegenerated && <p className="text-xs text-red-500">This request is expired. Please click on 'Regenerate Mail' to generate a new one.</p>}
            <div className="text-center mt-6">
              {isRegenerated ? (
                <p className="text-green-600">Mail regenerated successfully!</p>
              ) : (
                <button
                  onClick={() => handleRegenerateMail(tokenData)}
                  disabled={isRetriggering}
                  className="inline-block px-6 py-2 text-white bg-primary rounded-md transition-all duration-300 ease-in-out"
                >
                  {isRetriggering ? "Processing..." : "Regenerate Mail"}
                </button>
              )}
            </div>
            </>
          ) : (
            showCommentBox ? <div>
              <textarea
                className="w-[100%] p-2 border border-gray-300 rounded-md resize-vertical min-h-[80px]"
                placeholder="Add your comments here..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <div className="text-center mt-6">
                <button
                  onClick={() => handleApprovalToggle("CLOSED")}
                  disabled={isLoading}
                  className="inline-block px-6 py-2 text-white bg-primary rounded-md transition-all duration-300 ease-in-out mr-4"
                >
                  Approve Task
                </button>
                <button
                  onClick={() => handleApprovalToggle("CREATED")}
                  disabled={isLoading}
                  className="inline-block px-6 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md transition-all duration-300 ease-in-out"
                >
                  Reopen Task
                </button>
              </div>
            </div>
            : <p></p>
          )}
        </div>

        <div className="bg-[#f2f5f8] text-center p-5">
          <p className="text-sm text-gray-500">
            Copyright © 2025 Rulezero, All rights reserved.
            <br />
            Hissa is a registered trademark of Rulezero.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TaskApprovalEmail;
