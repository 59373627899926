import { useState } from "react";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import TermsAndPolicy from "./TermsAndPolicy";
import useIsMobile from "../utils/detectDevice";
import { handleClickForTracking } from "../analytics";
import { AnalyticsConstant } from "../AnalyticsContant";

function Footer() {
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    header: string;
  }>({ open: false, header: "" });
  const { isMobile } = useIsMobile();
  return (
    <>
      <Dialog
        open={dialogState.open}
        onClose={() => setDialogState({ header: "", open: false })}
      >
        <TermsAndPolicy
          type={dialogState.header}
          onClose={() => setDialogState({ header: "", open: false })}
        />
      </Dialog>
      <footer
        className={` ${
          isMobile
            ? "flex flex-col items-center"
            : "md:flex md:items-center md:justify-between md:p-6"
        }`}
      >
        <span className="text-sm text-gray-500 sm:text-center ">
          © {new Date().getFullYear() + " "}
          <a
            onClick={() => {
              handleClickForTracking(
                AnalyticsConstant.open_terms_and_condition
              );
              setDialogState({ open: true, header: "Terms And Services" });
            }}
            className="cursor-pointer hover:underline"
          >
            Hissa™
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 sm:mt-0">
          <li></li>
          <li>
            <a
              onClick={() => {
                handleClickForTracking(AnalyticsConstant.open_privacy_policy);
                setDialogState({ open: true, header: "Privacy Policy" });
              }}
              className="mr-4 cursor-pointer hover:underline md:mr-6"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                handleClickForTracking(
                  AnalyticsConstant.open_terms_and_condition
                );
                setDialogState({ open: true, header: "Terms And Services" });
              }}
              className="sm:block hidden cursor-pointer hover:underline"
            >
              Terms and Conditions
            </a>
            <a
              onClick={() => {
                handleClickForTracking(
                  AnalyticsConstant.open_terms_and_condition
                );
                setDialogState({ open: true, header: "Terms And Services" });
              }}
              className="sm:hidden block cursor-pointer hover:underline"
            >
              T&C
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Footer;
