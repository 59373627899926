import React, { useState } from "react";
import { Info } from "lucide-react";

const InfoTooltip = ({ message }: { message: string }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative flex flex-nowrap align-center">
      <Info
        className="ml-1 w-4 h-4 text-secondary cursor-pointer"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />

      {showTooltip && (
        <div className="fixed bottom-24 left-2/5 -translate-x-1/2 mt-2 bg-gray-700 text-white text-sm p-3 rounded shadow-lg w-72 z-[9999] text-justify">
        {message}
      </div>
      )}
    </div>
  );
};

export default InfoTooltip;
