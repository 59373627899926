import { useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon, FolderPlusIcon } from "@heroicons/react/24/outline";
import { Input, Required, TextArea } from "../shared/InputField";
import { HStack } from "../shared/utils";
import {
  useCreateProject,
  useGetCompanies,
  useGetProjectTypes,
} from "../queries/admin";
import { toast } from "react-toastify";
import { queryClient } from "../queries/client";
import { useNavigate } from "react-router-dom";
import { downloadS3File } from "../shared/DownloadFile";
import { useAuthStore } from "../store/useAuthStore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleClickForTracking } from "../analytics";
import { AnalyticsConstant } from "../AnalyticsContant";
import Select from "react-select";
import { Icon } from "@iconify/react";

export type CustomTaskTemplateType = {
  name: string;
  id: string;
  projectType: string;
  fileUrl: string;
};

const CreateTemplateModal = ({
  template,
  existingProjectType,
  isOpen,
  onClose,
  onCreateTemplate,
}: {
  template?: any;
  existingProjectType: any;
  isOpen: any;
  onClose: any;
  onCreateTemplate: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>(existingProjectType);

  const { data: availableProjectTypes } = useGetProjectTypes();

  const projectType =
    availableProjectTypes?.map((template: any) => {
      return { label: template.type, value: template };
    }) || [];

  const formik = useFormik({
    initialValues: template
      ? {
          name: template?.name ?? "",
          projectType: template?.projectType ?? "",
        }
      : {
          name: "",
          projectType: existingProjectType.label ?? "",
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      projectType: Yup.string().required("Project Type is required"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);
      const finalData = {
        projectType: selectedProject.label,
        templateName: values.name,
      };
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      formData.append("data", JSON.stringify(finalData));

      try {
        const baseUrl =
          process.env.REACT_APP_API ||
          "https://dev-taskmanager-api.hissa.com/v1/";
        const response = await fetch(`${baseUrl}/project/subTemplate`, {
          method: "POST",
          headers: {
            AccessToken: `${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          const result = await response.json();

          toast(result.message, {
            type: "error",
            autoClose: 2000,
          });
          throw new Error(result.message);
        }
        toast("template Created Successfully", {
          type: "success",
          autoClose: 2000,
        });
        queryClient.invalidateQueries("subTemplates");
        queryClient.invalidateQueries("projectTypes");
        onCreateTemplate();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
    },
  });

  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { accessToken } = useAuthStore();

  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event?.target?.files?.[0]) return;
    const fileData = event.target.files[0];
    const allowedFileExtensions = [".xlsm", ".xlsx"];
    if (
      fileData &&
      allowedFileExtensions.some((extension) =>
        fileData.name.endsWith(extension)
      )
    ) {
      setFile(fileData);
      const formData = new FormData();
      formData.append("file", fileData);
    }
  };

  const { data: companies } = useGetCompanies("");

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <HStack className="items-center">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        handleClickForTracking(
                          AnalyticsConstant.create_project_close_icon_modal
                        );
                        onClose();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <HStack className="items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FolderPlusIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-base pb-2 pl-2 font-semibold leading-6 text-gray-900"
                    >
                      {template ? "Update template" : "Create Sub Template"}
                    </Dialog.Title>
                  </HStack>
                </HStack>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:ml-4 md:ml-0 w-full sm:mt-0 sm:text-left">
                    <div className="mt-4">
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Sub Template Name <Required />
                        </label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.name && (
                          <div className="text-red-500 text-xs mt-1">
                            {formik.errors.name as any}
                          </div>
                        )}
                      </div>

                      {!template && (
                        <div className="mb-4 w-full">
                          <label
                            htmlFor="projectType"
                            className="block text-sm text-left font-medium text-gray-700"
                          >
                            Project Type <Required />
                          </label>

                          <Select
                            className="text-sm"
                            options={projectType}
                            name="company"
                            classNamePrefix="select2-selection"
                            value={selectedProject}
                            onChange={(e: any) => {
                              formik.setFieldValue("projectType", e.label);
                              setSelectedProject(e);
                            }}
                            maxMenuHeight={180}
                            menuShouldScrollIntoView={false}
                          />
                          {formik.errors.projectType && (
                            <div className="text-red-500 text-xs mt-1">
                              {formik.errors.projectType as any}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="relative border w-full py-4 px-4 border-dashed border-green-600 bg-green-50 rounded-xl my-6">
                        <p className="text-green-600">
                          Upload an Excel file to set up the template.{" "}
                          <span
                            className="underline text-indigo-700 cursor-pointer"
                            onClick={() => {
                              handleClickForTracking(
                                AnalyticsConstant.create_project_download_template_modal
                              );
                              downloadS3File(
                                "https://equion-dev.s3.us-west-2.amazonaws.com/task-manager-sub-template-file.xlsx"
                              );
                            }}
                          >
                            download here
                          </span>
                        </p>
                        <HStack className="justify-start mt-4">
                          <input
                            ref={fileRef}
                            onChange={(e) => handleAddFile(e)}
                            multiple={false}
                            type="file"
                            accept=".xlsx, .xlsm"
                            hidden
                          />
                          <button
                            onClick={() => {
                              handleClickForTracking(
                                AnalyticsConstant.create_project_upload_button_modal
                              );
                              fileRef.current.click();
                            }}
                            className="px-2 py-1 bg-green-600 text-white rounded-md text-sm tracking-wide"
                          >
                            {file ? "1 file Selected" : "Upload Template"}
                          </button>
                          {file && (
                            <>
                              <p className="text-xs ml-2 border rounded px-2 py-1">
                                {file?.name ?? ""}
                              </p>
                            </>
                          )}
                        </HStack>
                      </div>

                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          // type="submit"
                          onClick={(e) => formik.handleSubmit()}
                          className={`inline-flex w-full justify-center rounded-md ${
                            !file
                              ? "bg-gray-500 cursor-not-allowed"
                              : "bg-indigo-600  hover:bg-indigo-500"
                          } px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto`}
                          disabled={!file}
                        >
                          {loading ? (
                            <HStack className="items-center">
                              <p
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please wait
                              </p>
                              <Icon
                                className="animate-spin ml-2"
                                icon="lucide:loader-2"
                                width={18}
                              />
                            </HStack>
                          ) : (
                            "Create"
                          )}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => {
                            handleClickForTracking(
                              AnalyticsConstant.create_project_cancel_button_modal
                            );
                            onClose();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateTemplateModal;
