export const AnalyticsConstant = {
  sidebar_click_close_mobile: "sidebar_click_close_mobile",
  sidebar_click_open_mobile: "sidebar_click_open_mobile",
  sidebar_navigation: "sidebar_navigation",
  sidebar_project_navigation: "sidebar_project_navigation",
  sidebar_create_project: "sidebar_create_project",
  navbar_create_task: "navbar_create_task",
  member_close_icon_modal: "member_close_icon_modal",
  member_cancel_button_modal: "member_cancel_button_modal",
  member_delete_button_modal: "member_delete_button_modal",
  project_close_icon_modal: "project_close_icon_modal",
  project_cancel_button_modal: "project_cancel_button_modal",
  project_delete_button_modal: "project_delete_button_modal",
  task_close_icon_modal: "task_close_icon_modal",
  task_cancel_button_modal: "task_cancel_button_modal",
  task_delete_button_modal: "task_delete_button_modal",
  logout_close_icon_modal: "logout_close_icon_modal",
  logout_cancel_button_modal: "logout_cancel_button_modal",
  logout_logout_button_modal: "logout_logout_button_modal",
  reminder_close_icon_modal: "reminder_close_icon_modal",
  reminder_cancel_button_modal: "reminder_cancel_button_modal",
  reminder_confirm_button_modal: "reminder_confirm_button_modal",
  reminder_remove_assignee_button_modal: "reminder_confirm_button_modal",
  reminder_add_assignee_button_modal: "reminder_confirm_button_modal",
  create_project_close_icon_modal: "create_project_close_icon_modal",
  create_project_download_template_modal:
    "create_project_download_template_modal",
  create_project_upload_close_icon_modal:
    "create_project_upload_close_icon_modal",
  create_project_create_project_button_modal:
    "create_project_create_project_button_modal",
  create_project_cancel_button_modal: "create_project_cancel_button_modal",
  create_project_upload_button_modal: "create_project_upload_button_modal",
  update_group_task_close_icon_button_modal:
    "update_group_task_close_icon_button_modal",
  update_group_task_discard_button_modal:
    "update_group_task_discard_button_modal",
  update_group_task_continue_withoutoverride_button_modal:
    "update_group_task_continue_withoutoverride_button_modal",
  update_group_task_override_button_modal:
    "update_group_task_override_button_modal",
  upload_link_close_icon_button_modal: "upload_link_close_icon_button_modal",
  upload_link_cancel_button_modal: "upload_link_cancel_button_modal",
  upload_link_upload_button_modal: "upload_link_upload_button_modal",
  error404_go_back_home: "error404_go_back_home",
  activity_filter_tab: "activity_filter_tab",
  table_filter_tab: "table_filter_tab",
  table_column_tab: "table_column_tab",
  table_create_task_button: "table_create_task_button",
  table_group_project_button: "table_group_project_button",

  project_table_filter_tab: "project_table_filter_tab",
  project_table_column_tab: "project_table_column_tab",
  project_table_create_task_button: "project_table_create_task_button",

  calender_filter_button: "calender_filter_button",
  flyout_profile_navigation: "flyout_profile_navigation",

  list_toggle_button: "list_toggle_button",
  calender_toggle_button: "calender_toggle_button",
  kanban_toggle_button: "kanban_toggle_button",

  mail_approval_button: "mail_approval_button",
  mail_revoke_button: "mail_revoke_button",

  forgot_password_cancel_button: "forgot_password_cancel_button",
  forgot_password_submit_button: "forgot_password_submit_button",

  login_signin_button: "login_signin_button",
  login_forgot_password_button: "login_forgot_password_button",

  reset_password_navigate_login: "reset_password_navigate_login",
  reset_password_submit_button: "reset_password_submit_button",

  calender_change_month: "calender_change_month",
  calender_select_date_on_calender: "calender_select_date_on_calender",
  calender_task_selected_on_calender: "calender_task_selected_on_calender",
  calender_task_filter_tab: "calender_task_filter_tab",
  calender_task_card_clicked: "calender_task_card_clicked",

  privacy_policy_okay_button: "privacy_policy_okay_button",

  open_terms_and_condition: "open_terms_and_condition",
  open_privacy_policy: "open_privacy_policy",

  settings_save_changes_button: "settings_save_changes_button",
  home_open_assigned_task: "home_open_assigned_task",
  home_open_managed_task: "home_open_managed_task",
  home_tab_changed: "home_tab_changed",

  sse_close_icon_clicked: "sse_close_icon_clicked",
  sse_download_clicked: "sse_download_clicked",
  sse_remove_icon_clicked: "sse_remove_icon_clicked",

  list_status_filter_tab: "list_status_filter_tab",
  list_project_filter_tab: "list_project_filter_tab",

  sidebar_addtask_close_icon: "sidebar_addtask_close_icon",
  sidebar_addtask_create_task: "sidebar_addtask_create_task",
  sidebar_addtask_duplicate_task: "sidebar_addtask_duplicate_task",
  sidebar_addtask_cancel_button: "sidebar_addtask_cancel_button",

  sidebar_close_icon: "sidebar_close_icon",
  sidebar_open_reminder: "sidebar_open_reminder",
  sidebar_open_delete: "sidebar_open_delete",
  sidebar_edit_feild: "sidebar_edit_feild", //appended with feild edit
  sidebar_save_feild: "sidebar_save_feild", //appended with feild save
  sidebar_cancel_feild: "sidebar_cancel_feild", //appended with feild cancel
  sidebar_assignee_remove_icon: "sidebar_assignee_remove_icon",
  sidebar_tag_remove_icon: "sidebar_tag_remove_icon",
  sidebar_requiredDocs_remove_icon: "sidebar_requiredDocs_remove_icon",
  sidebar_activity_tab: "sidebar_activity_tab",
  sidebar_file_tab: "sidebar_file_tab",
  sidebar_comment_tab: "sidebar_comment_tab",
  sidebar_comment_added: "sidebar_comment_added",
  sidebar_comment_edited: "sidebar_comment_edited",
  sidebar_comment_deleted: "sidebar_comment_deleted",
  sidebar_add_link: "sidebar_add_link",
  sidebar_download_selected: "sidebar_download_selected",
  sidebar_download_attachment: "sidebar_download_attachment",
  sidebar_add_attachments: "sidebar_add_attachments",
  product_switch: "product_switch",

  attachment_clicked: "attachment_clicked",
  attachment_download: "attachment_download",
  attachment_delete: "attachment_delete",
  attachment_add_link: "attachment_add_link",
  attachment_upload: "attachment_upload",
  attachment_toggle: "attachment_toggle",
  attachment_grid: "attachment_grid",
  attachment_list: "attachment_list",
  attachment_download_all: "attachment_download_all",
  attachment_download_selected: "attachment_download_selected",

  table_save_clear: "table_save_clear",
  table_filter_toggle: "table_filter_toggle",
  table_column_toggle: "table_column_toggle",

  member_add: "member_add",
  member_add_submit: "member_add_submit",
  member_add_cancel: "member_add_cancel",
  member_remove: "member_remove",

  projectpage_export_to_excel: "projectpage_export_to_excel",
  projectpage_import_from_excel: "projectpage_import_from_excel",
  projectpage_update_project: "projectpage_update_project",
  projectpage_delete_project: "projectpage_delete_project",
};
