import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Info } from "lucide-react";
import { Fragment, useState } from "react";

export function ShowInfoPopover({ message }: { message: string }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover className="relative flex flex-nowrap align-bottom">
      <PopoverButton
        className="cursor-pointer"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Info className="ml-1 w-4 h-4 text-secondary" />
      </PopoverButton>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="fixed bottom-10 left-2/4 -translate-x-1/2 mt-2 min-w-[350px] bg-white border border-gray-200 shadow-lg rounded-lg p-4 z-[9999]">
          {" "}
          <div className="text-justify text-gray-800">{message}</div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}
