import { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack } from "../shared/utils";
import { toast } from "react-toastify";
import { Mail, X } from "lucide-react";
import { queryClient } from "../queries/client";
import { useNavigate } from "react-router-dom";
import { useSendReminder } from "../queries/admin";
import SearchAutoCompleteDropDown, {
  SwitchDropDownModel,
} from "../pages/myTask/SearchInputBox";
import { UserProjectRoles } from "../api/userRole";
import { LabelText } from "../pages/myTask/SideBarPanel";
import { useAuthStore } from "../store/useAuthStore";
import { useGetUserTaskRoles } from "../queries/userRole";
import { EventButton } from "../shared/CTAButtonComponents";
import { handleClickForTracking } from "../analytics";
import { AnalyticsConstant } from "../AnalyticsContant";
import { ShowInfoPopover } from "../shared/InfoComponent";

const MailReminderModal = ({
  taskIdData,
  projectId,
  taskNameData,
  isOpen,
  onClose,
}: {
  taskIdData: any;
  projectId: any;
  taskNameData: any;
  isOpen: any;
  onClose: any;
}) => {
  const [loading, setLoading] = useState(false);
  const { data: userProjectRoles } = useGetUserTaskRoles(taskIdData);
  const { mutate: sendReminder } = useSendReminder();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const users = assignee.map((ele) => ele.id);
    setLoading(true);
    sendReminder(
      {
        notifiers: users,
        projectId: projectId,
        taskId: taskIdData,
      },
      {
        onSuccess: () => {
          setLoading(false);
          queryClient.invalidateQueries("getAllProjects");
          queryClient.invalidateQueries("overview");
          toast("Reminder sent successfully!", {
            type: "success",
            autoClose: 2000,
          });
          onClose();
        },
        onError: () => {
          setLoading(false);
          toast(e.response.data.message, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };
  const [selectedAssignee, setSelectedAssignee] = useState<SwitchDropDownModel>(
    { name: "", id: "", type: "" }
  );
  const [assignee, setAssignee] = useState<SwitchDropDownModel[]>([]);

  const isAdmin = useAuthStore.getState().role === "ADMIN";
  function createAssignee(value?: SwitchDropDownModel) {
    handleClickForTracking(
      AnalyticsConstant.reminder_add_assignee_button_modal
    );
    if (value) {
      setAssignee([...(assignee ?? []), value]);
    }
  }

  useEffect(() => {
    const allRoles = (userProjectRoles ?? [])
      .filter((user) => user.role === "ASSIGNEE")
      .map((user: UserProjectRoles) => ({
        name: user.name,
        type: user.email,
        id: user.userId,
      }));
    setAssignee(allRoles);
  }, [userProjectRoles]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <VStack className="items-start">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        handleClickForTracking(
                          AnalyticsConstant.reminder_close_icon_modal
                        );
                        onClose();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <HStack className="items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <Mail
                        className="h-6 w-6 text-blue-500"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-base pb-2 pl-2 pt-4 tracking-wide font-semibold leading-6 text-gray-900"
                    >
                      Send Due Reminder
                      <p className="text-gray-500 tracking-normal font-medium text-xs">
                        This event will send a due reminder mail
                      </p>
                    </Dialog.Title>
                  </HStack>
                  <VStack>
                    {<LabelText text={"Recipient's :"} />}
                    {userProjectRoles?.filter(
                      (user) => user.role === "ASSIGNEE"
                    ).length === 0 && (
                      <p className="text-red-400 text-xs">
                        {
                          "No assignees found to send the remainder. Please add an assignee."
                        }
                      </p>
                    )}

                    <div className="mb-4">
                      <div className="flex flex-wrap gap-2 mb-2">
                        {(assignee ?? []).map((roles, index) => (
                          <span
                            key={index}
                            className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
                          >
                            {roles.name}{" "}
                            <ShowInfoPopover message={roles.type} />
                            {isAdmin && (
                              <X
                                className="w-4 h-4 ml-2 cursor-pointer"
                                onClick={() => {
                                  handleClickForTracking(
                                    AnalyticsConstant.reminder_remove_assignee_button_modal
                                  );
                                  const newArray = assignee.filter(
                                    (ele) => ele.id !== roles.id
                                  );
                                  setAssignee(newArray);
                                }}
                              />
                            )}
                          </span>
                        ))}
                      </div>
                      {isAdmin &&
                        (
                          (userProjectRoles ?? []).filter(
                            (user) => user.role === "ASSIGNEE"
                          ) ?? []
                        ).length !== assignee.length && (
                          <div className="h-10 mt-2 w-full">
                            <SearchAutoCompleteDropDown
                              placeholder="Add assignee"
                              width="w-96"
                              onChange={(e) => {}}
                              data={
                                (userProjectRoles ?? [])
                                  .filter((user) => user.role === "ASSIGNEE")
                                  .map((user: UserProjectRoles) => ({
                                    name: user.name,
                                    type: user.email,
                                    id: user.userId,
                                  }))
                                  .filter(
                                    (ele) =>
                                      !assignee.find(
                                        (ele2) => ele2.id === ele.id
                                      )
                                  ) as SwitchDropDownModel[]
                              }
                              getSelectedValue={createAssignee}
                              selectedOption={
                                selectedAssignee as SwitchDropDownModel
                              }
                              field={"name" as keyof SwitchDropDownModel}
                            />
                          </div>
                        )}
                    </div>
                  </VStack>
                </VStack>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:ml-4 w-full sm:mt-0 sm:text-left">
                    <form onSubmit={handleSubmit} className="mt-4">
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-4">
                        <EventButton
                          loading={loading}
                          disabled={
                            userProjectRoles?.filter(
                              (user) => user.role === "ASSIGNEE"
                            ).length === 0 || assignee?.length === 0
                          }
                          text={"Confirm"}
                          onClick={() => {}}
                        />

                        <button
                          type="button"
                          className="flex items-center justify-center px-4 py-2 w-full rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => {
                            handleClickForTracking(
                              AnalyticsConstant.reminder_cancel_button_modal
                            );
                            onClose();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MailReminderModal;
