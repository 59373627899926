import { useEffect, useState } from "react";
import { Check, X } from "lucide-react";
import { HStack, VStack } from "../../shared/utils";
import {
  useCreateUserProjectRole,
  useDeleteUserProjectRole,
  useGetUserProjectRoles,
  useUpdateUserProjectRole,
} from "../../queries/userRole";
import OptionMenus from "./OptionMenus";

import { queryClient } from "../../queries/client";
import { toast } from "react-toastify";
import { UserUpdateAvatar } from "../myTask/UserUpdate";
import { useAuthStore } from "../../store/useAuthStore";
import DeleteTaskModal from "../../modal/DeleteTask";
import DeleteMemberModal from "../../modal/DeleteMember";
import { ButtonSize, PrimaryCTAButton } from "../../shared/CTAButtonComponents";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";

const roles = ["VIEWER", "MEMBER", "ADMIN"];
const colorPalette = [
  "bg-red-100",
  "bg-blue-100",
  "bg-green-100",
  "bg-yellow-100",
  "bg-purple-100",
  "bg-pink-100",
  "bg-indigo-100",
  "bg-teal-100",
];

const textColorPalette = [
  "text-red-600",
  "text-blue-600",
  "text-green-600",
  "text-yellow-600",
  "text-purple-600",
  "text-pink-600",
  "text-indigo-600",
  "text-teal-600",
];

export interface MemberType {
  email: string;
  id: string;
  name: string;
  projectId: string;
  role: string;
  userId: string;
}

export function MembersTab({ project }: { project: any }) {
  const { mutate: deleteUserProjectRole } = useDeleteUserProjectRole();

  const { mutate: createUserProjectRole } = useCreateUserProjectRole();
  const { mutate: updateTheRole } = useUpdateUserProjectRole();
  const [members, setMembers] = useState<MemberType[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const { id } = useAuthStore();
  const { data: userProjectRoles } = useGetUserProjectRoles(project.id);
  useEffect(() => {
    const user = userProjectRoles?.find(
      (data) => data.role === "ADMIN" && data.userId === id
    );
    if (user) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userProjectRoles]);

  useEffect(() => {
    if (userProjectRoles && userProjectRoles.length > 0)
      setMembers(userProjectRoles);
  }, [userProjectRoles]);

  const [newMember, setNewMember] = useState({
    email: "",
    role: "MEMBER",
  });
  const [isAddingMember, setIsAddingMember] = useState(false);

  const handleRoleChange = (memberId: any, email: string, newRole: any) => {
    updateTheRole(
      {
        projectId: memberId,
        email: email,
        role: newRole,
      },
      {
        onSuccess: () => {
          toast("Updated User Detail Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          queryClient.invalidateQueries("getUserProjectRoles");
        },
        onError: (err: any) => {
          toast(err.response.data.message, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };

  const handleAddMember = () => {
    handleClickForTracking(AnalyticsConstant.member_add_submit);
    if (newMember.email) {
      createUserProjectRole(
        {
          projectId: project.id,
          email: newMember.email,
          role: newMember.role ?? "MEMBER",
        },
        {
          onSuccess: () => {
            toast("New Member Added Successfully!", {
              type: "success",
              autoClose: 2000,
            });
            queryClient.invalidateQueries("getUserProjectRoles");
          },
          onError: (err: any) => {
            toast(err.response.data.message, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );

      setNewMember({
        email: "",
        role: "MEMBER",
      });
      setIsAddingMember(false);
    }
  };
  const [isDeleteProject, setDeleteProject] = useState({
    state: false,
    name: "",
    id: "",
    projectId: "",
  });
  function isValidEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  return (
    <div className="bg-white rounded-lg shadow-md">
      <DeleteMemberModal
        id={`${isDeleteProject.id}`}
        projectId={`${isDeleteProject.projectId}`}
        memberName={`${isDeleteProject.name}`}
        isOpen={isDeleteProject.state ?? false}
        onClose={() => {
          setDeleteProject({
            state: false,
            name: "",
            id: "",
            projectId: "",
          });
        }}
      />
      <div className="px-8 py-2 border-b border-gray-200">
        <HStack className="justify-between">
          <VStack>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Project Members
            </h2>
            <p className="mt-1 sm:block hidden text-sm leading-6 text-gray-600">
              Added members gain access to project files and task-specific
              details.
            </p>
          </VStack>
          {isAdmin && (
            <PrimaryCTAButton
              className="h-8"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                handleClickForTracking(AnalyticsConstant.member_add);
                setIsAddingMember(true);
              }}
            >
              Add Member
            </PrimaryCTAButton>
          )}
        </HStack>
      </div>

      {isAddingMember && (
        <div className="p-6 border-b border-gray-200 bg-gray-50">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Add New Member
          </h3>
          <div className="flex space-x-4">
            <input
              type="email"
              placeholder="Enter the member Email"
              value={newMember.email}
              onChange={(e) =>
                setNewMember({ ...newMember, email: e.target.value })
              }
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <select
              value={newMember.role}
              onChange={(e) =>
                setNewMember({ ...newMember, role: e.target.value })
              }
              className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {roles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
            <button
              disabled={!isValidEmail(newMember.email)}
              onClick={handleAddMember}
              className={`${
                isValidEmail(newMember.email)
                  ? "bg-green-500"
                  : "bg-gray-500 cursor-not-allowed"
              } text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors duration-200`}
            >
              <Check className="w-5 h-5" />
            </button>
            <button
              onClick={() => {
                handleClickForTracking(AnalyticsConstant.member_add_cancel);
                setIsAddingMember(false);
              }}
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors duration-200"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}

      <div className="overflow-x-auto min-h-72 pb-20">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Member
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th>

              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody className=" divide-y divide-gray-200 bg-white">
            {members.map((member: any, index: number) => (
              <tr key={member.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <UserUpdateAvatar
                        key={member.id}
                        hideToolTip={false}
                        user={{ name: member.name ?? "" }}
                        isSelected={false}
                        onClick={() => {}}
                        bgColor={
                          colorPalette[index % (colorPalette.length - 1)]
                        }
                        textColor={
                          textColorPalette[
                            index % (textColorPalette.length - 1)
                          ]
                        }
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {member.name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {member.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <select
                    value={member.role}
                    onChange={(e) =>
                      handleRoleChange(project.id, member.email, e.target.value)
                    }
                    className="mt-1 block w-48 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    {roles.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                  <OptionMenus
                    options={[
                      {
                        name: "Remove Member",
                        onClick: () => {
                          handleClickForTracking(
                            AnalyticsConstant.member_remove
                          );
                          console.log("Member", member);
                          setDeleteProject({
                            state: true,
                            name: `${member.name}`,
                            id: `${member.userId}`,
                            projectId: `${project.id}`,
                          });
                        },
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
