import { ChevronDown } from "lucide-react";
import React from "react";
import { StatesModel } from "../myTask/UserStateChange";

const StateBadge = ({
  selectedOption,
  isPreview = false,
  onClick,
}: {
  selectedOption: StatesModel;
  isPreview?: boolean;
  onClick: () => void;
}) => {
  const bgColor = `bg-[${selectedOption?.bgColor}]`;
  const textColor = `text-[${selectedOption?.textColor}]`;

  const classs =
    `inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium` +
    " " +
    bgColor +
    " " +
    textColor;
  
  return (
    <button
      style={{
        background: selectedOption?.bgColor,
        color: selectedOption?.textColor,
      }}
      onClick={onClick}
      className={classs}
    >
      {selectedOption?.name}
      {!isPreview && <ChevronDown className="w-4 h-4" />}
    </button>
  );
};

export default StateBadge;
