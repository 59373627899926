import { useMemo } from "react";
import { TaskExtractModel } from "./useExtractMyTask";

const useExtractTasks = (projects: any): TaskExtractModel[] => {
  const allTasks = useMemo(() => {
    if (projects)
      return projects.reduce((acc: any, project: any) => {
        const statesMap = project.states.reduce(
          (acc: Record<string, boolean>, state: any) => {
            acc[state.name] = state.isFinalState;
            return acc;
          },
          {}
        );
        const projectTasks = project.tasks.map((task: any) => ({
          ...task,
          projectName: project.name,
          projectType: project.projectType,
          projectDescription: project.description,
          projectId: project.id,
          companyName: task.companyName || project.companyName,
          states: project?.states ?? [],
          companyId: task.companyId || project.companyId,
          showTaskDelay: project.showTaskDelay || false,
          isFinalState: statesMap[task.status],
        }));

        return [...acc, ...projectTasks];
      }, []);
    else return [];
  }, [projects]);

  return allTasks;
};

export default useExtractTasks;
