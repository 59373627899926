import { useMutation, useQuery } from "react-query";
import { handleEventForTracking } from "../analytics";
import {
  approveAmp,
  retriggerAmpMail,
  createProject,
  createTask,
  deleteProject,
  deleteSubTemplate,
  getAllProject,
  getAllTaskHistory,
  getAllTasks,
  getCompanies,
  getNotifications,
  getOverview,
  getProjectDetails,
  getProjectExport,
  getProjectTypes,
  getSubTemplates,
  getTaskDetails,
  sendReminder,
  updateProject,
  updateTask,
  updateTaskRead,
} from "../api/project";
import { queryClient } from "./client";
import { toast } from "react-toastify";
export function useCreateProject() {
  return useMutation({
    mutationKey: "create-project",
    mutationFn: createProject,
  });
}

export function useUpdateProject() {
  return useMutation({
    mutationKey: "update-project",
    mutationFn: updateProject,
    onSuccess: () => {
      queryClient.refetchQueries("getAllTask");
      handleEventForTracking({
        eventName: "create-project",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "create-project",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useSendReminder() {
  return useMutation({
    mutationKey: "send-reminder",
    mutationFn: sendReminder,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "send-reminder",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "send-reminder",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetAllProject() {
  return useQuery({
    queryKey: "getAllProjects",
    queryFn: getAllProject,
  });
}

export function useGetOverview() {
  return useQuery({
    queryKey: "overview",
    queryFn: getOverview,
  });
}

export function useGetProjectDetail(id: string) {
  return useQuery({
    queryKey: ["getProjectDetails", id],
    queryFn: getProjectDetails,
    select: (data) => data.data,
  });
}

export function useGetCompanies(id: string) {
  return useQuery({
    queryKey: ["getCompanies", id],
    queryFn: getCompanies,
    select: (data) => data.data,
  });
}

export function useGetNotifications() {
  return useQuery({
    queryKey: "getNotifications",
    queryFn: getNotifications,
  });
}

export function useGetAllTask(id: string) {
  return useQuery({
    queryKey: ["getAllTask", id],
    queryFn: getAllTasks,
    select: (data) => data.data,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
  });
}
export function useGetProjectExport(id: string) {
  return useQuery({
    queryKey: ["getProjectExport", id],
    queryFn: getProjectExport,
    select: (data) => data.data,
    enabled: false,
  });
}

export function useGetTask(id: string) {
  return useQuery({
    queryKey: ["getTask", id],
    queryFn: getTaskDetails,
  });
}

export function useGetAllTaskHistory(id: string) {
  return useQuery({
    queryKey: ["getAllTaskHistory", id],
    queryFn: getAllTaskHistory,
  });
}

export function useDeleteProject(id: string) {
  return useQuery({
    queryKey: ["deleteProject", id],
    queryFn: deleteProject,
    enabled: false,
  });
}

export function useCreateTask() {
  return useMutation({
    mutationKey: "create-task",
    mutationFn: createTask,
    onSuccess: () => {
      queryClient.invalidateQueries("getTask");
      queryClient.invalidateQueries("getAllTask");
      queryClient.invalidateQueries("overview");

      handleEventForTracking({
        eventName: "create-task",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "create-task",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateTask() {
  return useMutation({
    mutationKey: "update-task",
    mutationFn: updateTask,
    onSuccess: () => {
      queryClient.invalidateQueries("getTask");
      queryClient.invalidateQueries("getAllTask");
      queryClient.invalidateQueries("overview");
      queryClient.invalidateQueries("getAllTaskHistory");

      queryClient.refetchQueries("getTask");
      queryClient.refetchQueries("getAllTask");
      queryClient.refetchQueries("overview");
      queryClient.refetchQueries("getAllTaskHistory");

      handleEventForTracking({
        eventName: "update-task",
        success: true,
        eventType: "API",
      });
    },
    onError: (error: any) => {
      handleEventForTracking({
        eventName: "update-task",
        success: false,
        eventType: "API",
      });
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      toast(errorMessage, {
        type: "error",
        autoClose: 2000,
      });
    },
  });
}

export function useUpdateTaskRead() {
  return useMutation({
    mutationKey: "update-task-read",
    mutationFn: updateTaskRead,
    onSuccess: () => {
      queryClient.invalidateQueries("getTask");
      queryClient.invalidateQueries("getAllTask");
      queryClient.invalidateQueries("overview");
      queryClient.invalidateQueries("getAllTaskHistory");
      handleEventForTracking({
        eventName: "update-task-read",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "update-task-read",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useApproveAmp() {
  return useMutation({
    mutationKey: "approve-amp",
    mutationFn: approveAmp,
    onSuccess: () => {
      toast('Task Status Updated Successfully', {
        type: "success",
        autoClose: 2000,
      });
      handleEventForTracking({
        eventName: "approve-amp",
        success: true,
        eventType: "API",
      });
    },
    onError: (err: any) => {
      toast(err?.response?.data?.message || 'Error Processing the request', {
        type: "error",
        autoClose: 2000,
      });
      handleEventForTracking({
        eventName: "approve-amp",
        success: false,
        eventType: "API",
      });
    },
  });
}

export const useRetriggerAmpMail = () => {
  return useMutation({
    mutationKey: "regenerate-amp",
    mutationFn: retriggerAmpMail,
    onSuccess: () => {
      toast('Mail Regenerated Successfully!', {
        type: "success",
        autoClose: 2000,
      });
      handleEventForTracking({
        eventName: "retrigger-amp",
        success: true,
        eventType: "API",
      });
    },
    onError: (err: any) => {
      toast(err?.response?.data?.message || 'Error Processing the request', {
        type: "error",
        autoClose: 2000,
      });
      handleEventForTracking({
        eventName: "retrigger-amp",
        success: false,
        eventType: "API",
      });
    },
  });
};

export function useGetProjectTypes() {
  return useQuery({
    queryKey: "projectTypes",
    queryFn: getProjectTypes,
  });
}

export function useGetProjectSubTemplates() {
  return useQuery({
    queryKey: "subTemplates",
    queryFn: getSubTemplates,
  });
}

export function useDeleteSubTemplate() {
  return useMutation({
    mutationKey: "deleteSubTemplate",
    mutationFn: deleteSubTemplate,

    onSuccess: () => {
      queryClient.invalidateQueries("subTemplates");
      handleEventForTracking({
        eventName: "delete-sub-template",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "delete-sub-template",
        success: false,
        eventType: "API",
      });
    },
  });
}
