import React, { useState, useEffect, useRef, useMemo } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import { HStack, VStack } from "../../shared/utils";
import CalenderTask from "./CalenderTask";
import TaskDetailPanel from "../myTask/TaskDetailPanel";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetAllTask, useGetOverview } from "../../queries/admin";
import useExtractTasks from "../myTask/ExtractTaskHook";
import BlurOverlay from "./BlurOverLay";
import { taskstates } from "../myTask/IssueState";
import FilterButton from "../aggrid/FilterButton";
import { limitString } from "../../utils/string";
import OverDue from "./OverDue";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";
import { StatesModel } from "../myTask/UserStateChange";

interface Task {
  id: string;
  states: StatesModel[];
  name: string;
  startDate: string;
  endDate: string;
  status: string;
  // "COMPLETED" | "INPROGRESS" | "CREATED" | "CANCELLED" | "CLOSED";
}

interface PopoverPosition {
  x: number;
  y: number;
}

const getDaysInMonth = (year: number, month: number): number =>
  new Date(year, month + 1, 0).getDate();
const getFirstDayOfMonth = (year: number, month: number): number =>
  new Date(year, month, 1).getDay();

function CalendarPage({ allTasks }: { allTasks?: any[] }) {
  const [updatedFilters, setUploadFilters] = useState<{
    projectIds: string[];
    priorities: string[];
    statuses: string[];
  }>({
    projectIds: [],
    priorities: [],
    statuses: [],
  });

  // const { data: userData } = useGetOverview();
  // const { data: userData2, refetch: refetchGetAllTask } = useGetAllTask(
  //   projectId ?? ""
  // );
  // useEffect(() => {
  //   if (projectId) {
  //     refetchGetAllTask();
  //   }
  // }, [projectId]);
  // const allTask = useExtractTasks(userData);
  const tasks: any[] = useMemo(() => {
    const dataSet = allTasks ?? [];
    return dataSet
      .filter((ele: any) => {
        if (updatedFilters.projectIds.length === 0) {
          return true;
        }
        return updatedFilters.projectIds.includes(ele.projectName);
      })
      .filter((ele: any) => {
        if (updatedFilters.priorities.length === 0) {
          return true;
        }
        return updatedFilters.priorities.includes(ele.priority);
      })
      .filter((ele: any) => {
        if (updatedFilters.statuses.length === 0) {
          return true;
        }
        return updatedFilters.statuses.includes(ele.status);
      })
      .sort(
        (a: any, b: any) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );
  }, [updatedFilters, allTasks]);

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [filteredTasks, setFilteredTasks] = useState<Task[]>(tasks);
  const [expandedTasks, setExpandedTasks] = useState<string[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [popoverPosition, setPopoverPosition] = useState<PopoverPosition>({
    x: 0,
    y: 0,
  });

  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    setFilteredTasks(
      tasks.filter((task) => {
        const startDate = new Date(task.startDate);
        const endDate = new Date(task.endDate);
        return startDate <= endOfMonth && endDate >= startOfMonth;
      })
    );
  }, [currentDate, tasks]);

  const changeMonth = (increment: number): void => {
    handleClickForTracking(AnalyticsConstant.calender_change_month);
    setCurrentDate(
      (prevDate) =>
        new Date(prevDate.getFullYear(), prevDate.getMonth() + increment, 1)
    );
  };
  const uniqueStatusSet = Array.from(
    new Set(
      (allTasks ?? [])
        .map((task) => {
          const status = task.status.toUpperCase();

          switch (status) {
            case "CREATED":
              return "OPEN|CREATED";
            case "INPROGRESS":
              return "IN PROGRESS|INPROGRESS";
            case "COMPLETED":
              return "COMPLETE|COMPLETED";
            case "CLOSED":
              return "CLOSE|CLOSED";
            case "CANCELLED":
              return "CANCEL|CANCELLED";
            default:
              return `${status}|${status}`;
          }
        })
        .filter(Boolean)
    )
  ).map((item: any) => {
    const [label, key] = item.split("|");
    return { label, key };
  });
  const uniqueProjectNameSet = Array.from(
    new Set(
      (allTasks ?? [])
        .map((task) => {
          return task.projectName;
        })
        .filter(Boolean)
    )
  ).map((item: any) => {
    return item;
  });
  const uniquePrioritySet = Array.from(
    new Set(
      (allTasks ?? [])
        .map((task) => {
          const priority = task.priority.toUpperCase();

          switch (priority) {
            case "NORMAL":
              return "NORMAL";
            case "MEDIUM":
              return "MEDIUM";
            case "HIGH":
              return "HIGH";

            default:
              return null;
          }
        })
        .filter(Boolean)
    )
  ).map((item: any) => {
    return item;
  });

  const renderHeader = (): JSX.Element => {
    return (
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {currentDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>

        <div className="flex space-x-2">
          <FilterButton
            projectIds={uniqueProjectNameSet}
            priorities={uniquePrioritySet}
            statuses={uniqueStatusSet ?? []}
            onFilterChange={setUploadFilters}
          />
          <button
            onClick={() => changeMonth(-1)}
            className="p-1 rounded hover:bg-gray-200"
          >
            <ChevronLeft size={20} />
          </button>
          <button
            onClick={() => changeMonth(1)}
            className="p-1 rounded hover:bg-gray-200"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    );
  };
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [listOfTags, setListOfTags] = useState<string[]>([]);
  useEffect(() => {
    const list = new Set();
    (tasks ?? []).forEach((ele: any) =>
      (ele?.tags ?? []).forEach((ele: string) => list.add(ele))
    );
    setListOfTags(Array.from(list) as string[]);
  }, [tasks]);

  const getStatusColor = (task: Task): string => {
    return (
      task.states.find((data) => data.name === task.status)?.bgColor ??
      "#FF0000"
    );
  };
  const getStatusTextColor = (task: Task): string => {
    return (
      task.states.find((data) => data.name === task.status)?.textColor ??
      "#FF0000"
    );
  };

  const getBorderColor = (status: Task["status"]): string => {
    // switch (status) {
    //   case "COMPLETED":
    //     return " border-t-green-400 bg-green-200";
    //   case "INPROGRESS":
    //     return "border-t-yellow-400 bg-yellow-200";
    //   case "CREATED":
    //     return "border-t-blue-400 bg-blue-200";
    //   case "CANCELLED":
    //     return " border-t-red-400 bg-red-200";
    //   default:
    return "border-t";
    // }
  };
  const getBorderStateColor = (task: Task): string => {
    return (
      task.states.find((data) => data.name === task.status)?.textColor ??
      "#AA0000"
    );
  };
  const getBackStateColor = (task: Task): string => {
    return (
      task.states.find((data) => data.name === task.status)?.bgColor ??
      "#AA0000"
    );
  };
  const [clickedDate, setClickedDate] = useState("");

  const handleTaskClick = (
    e: React.MouseEvent<HTMLDivElement>,
    task: Task,
    date: any
  ): void => {
    e.stopPropagation();
    handleClickForTracking(
      AnalyticsConstant.calender_task_selected_on_calender
    );
    const clickDate = new Date(date ?? "");
    setClickedDate(clickDate.toDateString());
    if (selectedTask && selectedTask.id === task.id) {
      setSelectedTask(null);
      setShowTask(false);
    } else {
      setSelectedTask(task);
      setShowTask(true);
    }
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const calendarRect = calendarRef.current?.getBoundingClientRect();
    if (calendarRect) {
      setPopoverPosition({
        x: rect.left - calendarRect.left + rect.width / 2,
        y: rect.top - calendarRect.top + rect.height,
      });
    }
  };

  const renderDayCell = (date: Date): JSX.Element => {
    const dayTasks = filteredTasks.filter((task) => {
      const taskStart = new Date(task.startDate);
      const taskEnd = new Date(task.endDate);
      const isStartOrEnd =
        taskStart.toDateString() === date.toDateString() ||
        taskEnd.toDateString() === date.toDateString();
      const isExpanded = expandedTasks.includes(task.id);

      return (
        isStartOrEnd || (isExpanded && date >= taskStart && date <= taskEnd)
      );
    });
    const taskStart = new Date(selectedTask?.startDate ?? "");
    const taskEnd = new Date(selectedTask?.endDate ?? "");
    const isTaskEndDate = taskEnd.toDateString() === clickedDate;
    const isTaskStartDate = taskStart.toDateString() === clickedDate;

    if (isTaskStartDate && taskEnd.toDateString() === date.toDateString())
      dayTasks.sort((a, b) => {
        if (selectedTask && a.id === selectedTask.id) return -1;
        if (selectedTask && b.id === selectedTask.id) return 1;
        return 0;
      });

    if (isTaskEndDate && taskStart.toDateString() === date.toDateString())
      dayTasks.sort((a, b) => {
        if (selectedTask && a.id === selectedTask.id) return -1;
        if (selectedTask && b.id === selectedTask.id) return 1;
        return 0;
      });

    const today = new Date().toDateString() === date.toDateString();
    let dateBackgroundColor = today ? "#FFFFFF" : "#FFFFFF";
    let borderState = today ? "#FFFFFF" : "#FFFFFF";
    let borderBack = today ? "#FFFFFF" : "#FFFFFF";
    if (selectedTask) {
      const selectedTaskStart = new Date(selectedTask.startDate);
      const selectedTaskEnd = new Date(selectedTask.endDate);
      if (date >= selectedTaskStart && date <= selectedTaskEnd) {
        dateBackgroundColor = getBorderColor(selectedTask.status);
        borderState = getBorderStateColor(selectedTask);
        borderBack = getBackStateColor(selectedTask);
      } else {
        dateBackgroundColor = "#FFFFFF";
        borderState = "#FFFFFF";
        borderBack = "#FFFFFF";
      }
    }

    return (
      <div
        onClick={() => {
          handleClickForTracking(
            AnalyticsConstant.calender_select_date_on_calender
          );
          setSelectedDate(date);
        }}
        className={`cursor-pointer h-28 p-1 overflow-y-auto scrollbar-hide  relative  
          ${today ? "border-primary border-2" : ""}
         ${
           date.toDateString() === selectedDate.toDateString() && !today
             ? "border-2 border-blue-500"
             : "border border-gray-200 "
         }
        
        `}
      >
        <div
          style={{
            borderColor: borderState,
            background: borderBack,
          }}
          className={`font-bold -mx-1 -mt-1 mb-1 p-1 ${dateBackgroundColor}`}
        >
          <p
            className={` ${
              today
                ? "bg-primary w-fit rounded-full p-1 text-xs text-white"
                : ""
            }`}
          >
            {" "}
            {date.getDate()}
          </p>{" "}
        </div>
        <AnimatePresence>
          {dayTasks.map((task) => {
            const isStartDate =
              new Date(task.startDate).toDateString() === date.toDateString();
            const isEndDate =
              new Date(task.endDate).toDateString() === date.toDateString();
            const isSelected = selectedTask && selectedTask.id === task.id;

            let bgColor = "bg-gray-100";
            let textColor = "bg-gray-100";

            if (isSelected) {
              if (isStartDate) {
                bgColor = getStatusColor(task);
                textColor = getStatusTextColor(task);
              } else if (isEndDate) {
                bgColor = getStatusColor(task);
                textColor = getStatusTextColor(task);
              } else {
                bgColor = getStatusColor(task);
                textColor = getStatusTextColor(task);
              }
            }
            const border = taskstates.find(
              (ele) => ele.key === task.status
            )?.borderColor;
            const back = isSelected ? bgColor : "#FFFFFF";
            return (
              <motion.div
                key={task.id}
                layout
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{
                  duration: 1,
                  type: "spring",
                  stiffness: 100,
                  damping: 15,
                }}
                onClick={(e) => handleTaskClick(e, task, date)}
                className="mb-2 cursor-pointer "
              >
                <div
                  style={{
                    borderColor: textColor,
                    background: back,
                  }}
                  className={`text-xs p-1 -m-1 mb-1 cursor-pointer transition-colors duration-300  ${
                    isStartDate
                      ? `border-l-4`
                      : isEndDate
                      ? `border-r-4`
                      : "-mx-8"
                  } ${border} `}
                >
                  <span
                    style={{
                      background: bgColor,
                    }}
                    className={`w-2 h-2 rounded-full mr-1`}
                  ></span>
                  <span>
                    <span className="whitespace-nowrap text-ellipsiss ">
                      {isStartDate
                        ? `${limitString(task.name, 12)}`
                        : isEndDate
                        ? `${limitString(task.name, 12)}`
                        : ""}
                    </span>
                  </span>
                  <HStack className="items-center gap-1">
                    <OverDue task={task} />
                  </HStack>
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    );
  };

  const renderMonthView = (): JSX.Element => {
    const daysInMonth = getDaysInMonth(
      currentDate.getFullYear(),
      currentDate.getMonth()
    );
    const firstDayOfMonth = getFirstDayOfMonth(
      currentDate.getFullYear(),
      currentDate.getMonth()
    );
    const days: JSX.Element[] = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="bg-gray-100"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );

      date.setHours(date.getHours() + 5);
      date.setMinutes(date.getMinutes() + 30);
      days.push(
        <div key={`day-${day}`} className="relative">
          {renderDayCell(date)}
        </div>
      );
    }

    return (
      <div className="grid grid-cols-7 gap-1 bg-white rounded-xl">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="font-bold text-center py-2">
            {day}
          </div>
        ))}
        {days}
      </div>
    );
  };

  const isAdmin = useAuthStore.getState().role === "ADMIN";

  const [showTask, setShowTask] = useState(false);
  const [showTaskOnCalender, setShowTaskOnCalender] = useState("");
  return (
    <>
      <BlurOverlay
        isVisible={showTaskOnCalender !== ""}
        onClose={() => setShowTaskOnCalender("")}
      />
      <div className="grid grid-cols-3">
        <div
          ref={calendarRef}
          className="col-span-3 sm:col-span-2 container mx-auto pt-0 px-0 sm:px-4 pb-4"
        >
          <VStack className="relative">
            {renderHeader()}
            {renderMonthView()}
            <div className="absolute -top-3 right-0 z-40 w-[480px] hidden sm:block">
              {showTaskOnCalender !== "" && (
                <TaskDetailPanel
                  tags={listOfTags}
                  realTasks={tasks.find(
                    (task) => task.id === showTaskOnCalender
                  )}
                  onClose={() => setShowTaskOnCalender("")}
                  isAdmin={isAdmin}
                />
              )}
            </div>
          </VStack>
        </div>

        <VStack className="relative z-40 px-4 sm:px-0 hidden sm:block">
          {showTask ? (
            <TaskDetailPanel
              tags={listOfTags}
              realTasks={selectedTask}
              onClose={() => setShowTask(false)}
              isAdmin={isAdmin}
            />
          ) : (
            <CalenderTask
              tasks={tasks}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setShowTaskOnCalender={setShowTaskOnCalender}
            />
          )}
        </VStack>
      </div>

      <VStack className="relative z-40 px-0 block sm:hidden">
        <div className="absolute -top-3 right-0 z-40 w-[420px] block sm:hidden">
          {showTaskOnCalender !== "" && (
            <TaskDetailPanel
              tags={listOfTags}
              realTasks={tasks.find((task) => task.id === showTaskOnCalender)}
              onClose={() => setShowTaskOnCalender("")}
              isAdmin={isAdmin}
            />
          )}
        </div>
        {showTask ? (
          <TaskDetailPanel
            tags={listOfTags}
            realTasks={selectedTask}
            onClose={() => setShowTask(false)}
            isAdmin={isAdmin}
          />
        ) : (
          <CalenderTask
            tasks={tasks}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setShowTaskOnCalender={setShowTaskOnCalender}
          />
        )}
      </VStack>
    </>
  );
}

export default CalendarPage;
