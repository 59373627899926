import { useMutation, useQuery } from "react-query";
import { handleEventForTracking } from "../analytics";
import {
  createUserProjectRole,
  createUserTaskRole,
  deleteUserProjectRole,
  deleteUserTaskRole,
  getNotificationSetting,
  getUserProfile,
  getUserProjectRoles,
  getUserTaskRoles,
  updateNotificationSetting,
  updateUserProfile,
  updateUserProjectRole,
  updateUserTaskRole,
} from "../api/userRole";
import { queryClient } from "./client";
import { toast } from "react-toastify";

export function useCreateUserProjectRole() {
  return useMutation({
    mutationKey: "create-user-project-role",
    mutationFn: createUserProjectRole,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "create-user-project-role",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "create-user-project-role",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCreateUserTaskRole() {
  return useMutation({
    mutationKey: "create-user-task-role",
    mutationFn: createUserTaskRole,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "create-user-task-role",
        success: true,
        eventType: "API",
      });
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Failed to assign user");
      handleEventForTracking({
        eventName: "create-user-task-role",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateUserProjectRole() {
  return useMutation({
    mutationKey: "update-user-project-role",
    mutationFn: updateUserProjectRole,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "update-user-project-role",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "update-user-project-role",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateUserProfile() {
  return useMutation({
    mutationKey: "update-user-profile",
    mutationFn: updateUserProfile,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "update-user-profile",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "update-user-profile",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateNotificationSetting() {
  return useMutation({
    mutationKey: "updateNotificationSetting",
    mutationFn: updateNotificationSetting,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "update-user-project-role",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "update-user-project-role",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteUserProjectRole() {
  return useMutation({
    mutationKey: "delete-user-project-role",
    mutationFn: deleteUserProjectRole,
    onSuccess: () => {
      queryClient.invalidateQueries("getUserProjectRoles");
      handleEventForTracking({
        eventName: "delete-user-project-role",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {},
  });
}

export function useDeleteUserTaskRole() {
  return useMutation({
    mutationKey: "delete-user-task-role",
    mutationFn: deleteUserTaskRole,
    onSuccess: () => {
      queryClient.invalidateQueries("getUserProjectRoles");
      queryClient.invalidateQueries("getUserTaskRoles");
      handleEventForTracking({
        eventName: "delete-user-task-role",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {},
  });
}

export function useUpdateUserTaskRole() {
  return useMutation({
    mutationKey: "update-user-task-role",
    mutationFn: updateUserTaskRole,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "update-user-task-role",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "update-user-task-role",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetNotification(projectId: any) {
  return useQuery({
    queryKey: ["get-motification-setting", projectId],
    queryFn: getNotificationSetting,
  });
}

export function useGetUserProjectRoles(projectId: any) {
  return useQuery({
    queryKey: ["getUserProjectRoles", projectId],
    queryFn: getUserProjectRoles,
    select: (data) => data,
  });
}

export function useGetUserProfile() {
  return useQuery({
    queryKey: "getUserProfile",
    queryFn: getUserProfile,
    select: (data) => data,
  });
}

export function useGetUserTaskRoles(taskId: any) {
  return useQuery({
    queryKey: ["getUserTaskRoles", taskId],
    queryFn: getUserTaskRoles,
  });
}
